<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Guarantor
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-text-field
            label="Guarantor Name"
            v-model="fields.guarantor_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_name')"
            :error-messages="errors['guarantor_name']"
          ></v-text-field>
          <v-text-field
            label="Address Line 1"
            v-model="fields.guarantor_add1"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_add1')"
            :error-messages="errors['guarantor_add1']"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.guarantor_add2"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_add2')"
            :error-messages="errors['guarantor_add2']"
          ></v-text-field>
          <v-text-field
            label="City/Region"
            v-model="fields.guarantor_city_region"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_city_region')"
            :error-messages="errors['guarantor_city_region']"
          ></v-text-field>
          <v-text-field
            label="Postcode/Zip Code"
            v-model="fields.guarantor_postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_postcode')"
            :error-messages="errors['guarantor_postcode']"
          ></v-text-field>
          <v-autocomplete
            label="Country *"
            v-model="fields.guarantor_country"
            :items="availableCountries"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_country')"
            :error-messages="errors['guarantor_country']"
          ></v-autocomplete>
          <v-text-field
            label="Phone Number"
            v-model="fields.guarantor_phone_number"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_phone_number')"
            :error-messages="errors['guarantor_phone_number']"
          ></v-text-field>
          <v-text-field
            label="Email"
            v-model="fields.guarantor_email"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_email')"
            :error-messages="errors['guarantor_email']"
          ></v-text-field>
          <v-text-field
            label="Job Title"
            v-model="fields.guarantor_job_title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_job_title')"
            :error-messages="errors['guarantor_job_title']"
          ></v-text-field>
          <v-text-field
            label="Annual Income"
            v-model="fields.guarantor_annual_income"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('guarantor_annual_income')"
            :error-messages="errors['guarantor_annual_income']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      form: {},
      fields: {
        form_title: "guarantor",
        guarantor_name: null,
        guarantor_add1: null,
        guarantor_add2: null,
        guarantor_city_region: null,
        guarantor_postcode: null,
        guarantor_country: null,
        guarantor_phone_number: null,
        guarantor_email: null,
        guarantor_job_title: null,
        guarantor_annual_income: null,
      },
      errors: {},
    };
  },

  computed: {
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
  },

  methods: {
    open(form = null) {
      if (form !== null) {
        this.form = form;
        this.isEditing = true;
        this.fields.guarantor_name = form.guarantor_name;
        this.fields.guarantor_add1 = form.guarantor_add1;
        this.fields.guarantor_add2 = form.guarantor_add2;
        this.fields.guarantor_city_region = form.guarantor_city_region;
        this.fields.guarantor_postcode = form.guarantor_postcode;
        this.fields.guarantor_country = form.guarantor_country;
        this.fields.guarantor_phone_number = form.guarantor_phone_number;
        this.fields.guarantor_email = form.guarantor_email;
        this.fields.guarantor_job_title = form.guarantor_job_title;
        this.fields.guarantor_annual_income = form.guarantor_annual_income;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.formId = this.form.application_id;
      }

      this.$store
        .dispatch("easylets/applicationformsStore/saveForm", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.form = {};
      this.fields = {
        guarantor_name: null,
        guarantor_add1: null,
        guarantor_add2: null,
        guarantor_city_region: null,
        guarantor_postcode: null,
        guarantor_country: null,
        guarantor_phone_number: null,
        guarantor_email: null,
        guarantor_job_title: null,
        guarantor_annual_income: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
