<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card outlined class="mb-6">
          <v-card-subtitle
            class="d-flex justify-start align-center grey lighten-5"
          >
            <div class="flex-grow-1">Details</div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  depressed
                  color="blue lighten-4 blue--text"
                  v-on="on"
                  @click="$refs.guarantorDialog.open(form)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Customer's Information</span>
            </v-tooltip>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{{ form.guarantor_name }}</td>
                </tr>
                <tr>
                  <th>Address Line 1</th>
                  <td>{{ form.guarantor_add1 }}</td>
                </tr>
                <tr>
                  <th>Address Line 2</th>
                  <td>{{ form.guarantor_add2 }}</td>
                </tr>
                <tr>
                  <th>City/Region</th>
                  <td>{{ form.guarantor_city_region }}</td>
                </tr>
                <tr>
                  <th>Postcode/Zip Code</th>
                  <td>
                    {{ form.guarantor_postcode }}
                  </td>
                </tr>
                <tr>
                  <th>Country</th>
                  <td>
                    {{ form.guarantor_country }}
                  </td>
                </tr>
                <tr>
                  <th>Phone Number</th>
                  <td>
                    {{ form.guarantor_phone_number }}
                  </td>
                </tr>
                <tr>
                  <th>Email Address</th>
                  <td>
                    {{ form.guarantor_email }}
                  </td>
                </tr>
                <tr>
                  <th>Job Title</th>
                  <td>
                    {{ form.guarantor_job_title }}
                  </td>
                </tr>

                <tr>
                  <th>Annual Income</th>
                  <td>
                    {{ form.guarantor_annual_income }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card outlined class="mb-6">
          <v-card-subtitle
            class="d-flex justify-start align-center grey lighten-5"
          >
            <div class="flex-grow-1">Files</div>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr v-if="form.guarantor_payslips">
                  <td>Payslips</td>
                  <td class="text-right">
                    <v-btn
                      x-small
                      depressed
                      class="mr-2"
                      :href="form.asset_urls.guarantor_payslips"
                      target="_blank"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="form.guarantor_photo_id">
                  <td>Photo Id</td>
                  <td class="text-right">
                    <v-btn
                      x-small
                      depressed
                      class="mr-2"
                      :href="form.asset_urls.guarantor_photo_id"
                      target="_blank"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="form.guarantor_bank_statement">
                  <td>Bank statement</td>
                  <td class="text-right">
                    <v-btn
                      x-small
                      depressed
                      class="mr-2"
                      :href="form.asset_urls.guarantor_bank_statement"
                      target="_blank"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="form.guarantor_pdf">
                  <td>Guarantor Declaration</td>
                  <td class="text-right">
                    <v-btn
                      x-small
                      depressed
                      class="mr-2"
                      :href="form.asset_urls.guarantor_pdf"
                      target="_blank"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <guarantor-dialog ref="guarantorDialog" />
  </div>
</template>

<script>
import GuarantorDialog from "./components/GuarantorDialog.vue";

export default {
  components: {
    GuarantorDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Forms",
          disabled: false,
          to: { name: "module-easylets-applicationforms" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    form() {
      return this.$store.getters["easylets/applicationformsStore/get"];
    },
  },
};
</script>
